<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{}">
      <v-text-field
        v-model="formatedDate"
        v-mask="'##/##/####'"
        :label="label"
        hide-details="auto"
        append-icon="mdi-calendar"
        :error-messages="errorMessages"
        @blur="date = dateFormatIso(formatedDate)"
        @click:append="menu = true"
      />
    </template>
    <v-date-picker v-model="date" no-title class="ma-0" @input="onChangeDate" />
  </v-menu>
</template>

<script>
import dateFormat from '@/utils/date-format'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: [],
    },
  },

  data: () => ({
    menu: false,
    formatedDate: null,
    dateFormatIso: dateFormat.iso,
  }),

  computed: {
    date: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  watch: {
    date: {
      immediate: true,
      handler(newVal) {
        this.formatedDate = dateFormat.ptBr(newVal)
      },
    },
  },

  methods: {
    onChangeDate(newDate) {
      this.date = newDate
      this.menu = false
    },
  },
}
</script>
